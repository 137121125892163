/* eslint-disable */

require('jquery-nice-select');

const MIN_INTEREST_RATE = 9;
const MAX_INTEREST_RATE = 24;
const INTEREST_RATE_STEP = 3;
const DEFAULT_INTEREST_RATE = 15;

const DEFAULT_TERM = 12;

const $draggableDot = $('#draggable-dot');
const $initalisationElements = $('*[draggable!=true]', '.slick-track');
const $calculatorWidgetSlider = $('#calculator-widget-slide');
function init_calculator(slider_value) {
  slickify();
  if ($calculatorWidgetSlider.length) {
    widgetSlickify();
  }

  initSlider(slider_value || DEFAULT_INTEREST_RATE);

  $initalisationElements.unbind('dragstart');

  $draggableDot.on(
    'draggable mouseenter mousedown dragstart dragend touchstart',
    function (event) {
      event.stopPropagation();
    },
  );

  init_event_listener();
}

function widgetSlickify() {
  $calculatorWidgetSlider
    .slick({
      arrows: false,
      dots: true,
      slidesToShow: 1,
      infinite: false,
      slidesToScroll: 1,
    })
    .slick('slickFilter', ':even');
}

const $tabSelector = $('button.calculator-tab');
const $calculatorBlock = $('button.calculator-block');
const $calculatorForm = $('form#calculator-form');
const $calculatorApply = $('#calculator-loan-apply');
const $calculatorResetButton = $('#calculator-reset');
const $calculatorReturnButton = $('.return-button-wrapper');
const $calculatorTimeFrameButtons = $('#calculator-results-buttons button');
const $calculatorInput = $('input#calculator-loan-amount');
const $calculatorPeriodSelect = $('#calc-period-select');
const $calculatorCrossIcon = $('#calculator-cross-icon');

function init_event_listener() {
  $calculatorPeriodSelect.change(triggerCalculation);
  $calculatorInput.keydown(validateLoanAmountInput);
  $calculatorBlock.click(handleSelectRepaymentPeriod);
  $calculatorForm.submit(handleSubmitCalcForm);
  $tabSelector.click(handleClickCalcTab);
  $calculatorTimeFrameButtons.click(function (event) {
    toggleRepaymentTimeFrame(event.target.name);
  });
  $calculatorApply.click(handleClickApply);
  $calculatorResetButton.click(clickReset);
  $calculatorReturnButton.click(handleClickReturn);
  $calculatorCrossIcon.click(clearInput);
}

var slickInitialized = false;
var isCalculated = false;
var doit = false;
var shouldAnimation = true;

const $window = $(window);
$window.resize(function () {
  clearTimeout(doit);
  doit = setTimeout(slickify, 250);
});

const $calculatorSlider = $('#calculator-slide');
const $calculatorInterestNumber = $('#number-display');
function slickify() {
  const window_width = $window.width();
  if (!slickInitialized && window_width <= 768 && $calculatorSlider.length) {
    $calculatorSlider
      .slick({
        arrows: false,
        dots: true,
        slidesToShow: 1,
        infinite: false,
        slidesToScroll: 1,
      })
      .slick('slickFilter', ':even');
    slickInitialized = true;
  }
  if (slickInitialized && window_width > 768 && $calculatorSlider.length) {
    slickInitialized = false;
    $calculatorSlider.slick('slickUnfilter').slick('unslick');
    const text = $calculatorInterestNumber.text();
    initSlider(text);
    init_event_listener();
  }
}

const $calculatorProgressInside = $('.calculator-progress-bar-inside');
const $calculatorProgressBar = $('.calculator-progress-bar');
const $dotNumberWrapper = $('.dot-number-wrapper');
const $calculatorInterestRate = $('#calc-interest-rate');
function initSlider(value) {
  function changeSliderValue(text) {
    const value = (text - MIN_INTEREST_RATE) / INTEREST_RATE_STEP;
    const percentage = value * 20;
    const bgi =
      'linear-gradient(90deg, #FD55D8 ' + percentage + '%, #eceff4 0%)';
    const left = 'calc(' + percentage + '% - 22px)';
    $calculatorProgressInside.css('background-image', bgi);
    $calculatorProgressBar.css('background-image', bgi);
    $dotNumberWrapper.css('left', left);
    $calculatorInterestNumber.text(text);
    $calculatorInterestRate.children('div').each(function (index, element) {
      var $currentElement = $(this);
      if (index <= value) {
        $currentElement.addClass('selected-dot');
      } else {
        $currentElement.removeClass('selected-dot');
      }
    });
  }

  $draggableDot.slider({
    min: MIN_INTEREST_RATE,
    max: MAX_INTEREST_RATE,
    step: INTEREST_RATE_STEP,
    value: value,
    create: function (event, ui) {
      changeSliderValue(value);
    },
    slide: function (event, ui) {
      changeSliderValue(ui.value);
    },
    stop: function (event, ui) {
      triggerCalculation();
    },
  });
}

var timeout;
function _debounce(func, wait, immediate) {
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function triggerCalculation(wait) {
  const _wait = wait === undefined ? 500 : wait;
  if (isCalculated) {
    _debounce(function () {
      $calculatorForm.submit();
    }, _wait)();
  }
}

const $calculatorTabs = $('.calculator-tabs');
const $weeklySelector = $('#weekly-selector');
const $calculatorResults = $('.calculator-results');
function handleSubmitCalcForm(event) {
  event.preventDefault();
  const tab = $calculatorTabs.attr('value');
  const time_frame =
    tab === 'affordability'
      ? 'affordability'
      : $weeklySelector.val() === 'true'
      ? TAB_LABEL.WEEKLY
      : TAB_LABEL.MONTHLY;
  const formData = new FormData(event.target);
  const loan_amount = parseFloatCurrency(formData.get('amount'));
  const period = $calculatorPeriodSelect.val();
  const interest_rate = $calculatorInterestNumber.text();
  if (validateCalcLoanAmount(loan_amount, tab)) {
    isCalculated = true;
    if (shouldAnimation) {
      if ($window.width() <= 768) {
        // Check to see if the slide is already on the results
        // running slickGoTo will cause flicker if you're already on the panel
        const currentSlide = $calculatorSlider.slick('slickCurrentSlide');
        if (currentSlide === 0) {
          $calculatorSlider
            .on('setPosition', function (event, slick) {
              // 105px is margin top and bottom size;
              slick.$slides.css(
                'height',
                slick.$slideTrack.height() - 105 + 'px',
              );
            })
            .slick('slickUnfilter')
            .slick('slickGoTo', 2);
        }
      } else {
        $calculatorResults.addClass('calculator-results-slide-in');
      }
    }
    $calculatorWidgetSlider
      .on('setPosition', function (event, slick) {
        // 40px is margin top size;
        slick.$slides.css('height', slick.$slideTrack.height() - 105 + 'px');
      })
      .slick('slickUnfilter')
      .slick('slickGoTo', 2);
    calcRepaymentAndAffordability(
      loan_amount,
      period,
      interest_rate,
      time_frame,
    );
    shouldAnimation = true;
  }
}

const $calculatorFormErrorMessage = $('#calculator-form .error-message');
function validateCalcLoanAmount(amount, type) {
  var error;
  if (
    type === TAB_LABEL.REPAYMENTS &&
    (amount < LOAN_VALIDATION.REPAYMENTS_MIN_LOAN_AMOUNT ||
      amount > LOAN_VALIDATION.REPAYMENTS_MAX_LOAN_AMOUNT ||
      isNaN(amount))
  ) {
    error =
      'Loan amount must be between $' +
      LOAN_VALIDATION.REPAYMENTS_MIN_LOAN_AMOUNT / 1000 +
      'K and $' +
      LOAN_VALIDATION.REPAYMENTS_MAX_LOAN_AMOUNT / 1000 +
      'K';
    $calculatorFormErrorMessage.text(error);
    insertCalcResult({
      repayment_afforability_amount: '00.00',
      total_interest: '0.00',
      total_repayment: '00.00',
    });
    $calculatorInput.addClass('calculator-loan-amount__error');
    return false;
  }
  if (
    type === TAB_LABEL.AFFORADABILITY &&
    (amount < LOAN_VALIDATION.MIN_WEEKLY_AFFORADABILITY || isNaN(amount))
  ) {
    error =
      'Weekly repayment must be greater than $' +
      LOAN_VALIDATION.MIN_WEEKLY_AFFORADABILITY;
    $calculatorFormErrorMessage.text(error);
    insertCalcResult({
      repayment_afforability_amount: '00.00',
      total_interest: '0.00',
      total_repayment: '00.00',
    });
    $calculatorInput.addClass('calculator-loan-amount__error');
    return false;
  }
  $calculatorFormErrorMessage.text('');
  $calculatorInput.removeClass('calculator-loan-amount__error');
  return true;
}

function clearInput() {
  $calculatorInput.val('');
  $calculatorFormErrorMessage.text('');
  $calculatorInput.removeClass('calculator-loan-amount__error');
}

const AnnualFactorToWeeklyRateMapper = {
  9: 0.003304,
  12: 0.004367,
  15: 0.005412,
  18: 0.006442,
  21: 0.007455,
  24: 0.008454,
};

function PMT(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
}

function conv_number(expr, decplaces) {
  var str = '' + Math.round(eval(expr) * Math.pow(10, decplaces));
  while (str.length <= decplaces) {
    str = '0' + str;
  }

  var decpoint = str.length - decplaces;
  return str.substring(0, decpoint) + '.' + str.substring(decpoint, str.length);
}

function PV(rate, nper, pmt, fv) {
  // return pmt / rate * (1 - Math.pow(1 + rate, -nper));
  rate = parseFloat(rate);
  nper = parseFloat(nper);
  pmt = parseFloat(pmt);
  fv = parseFloat(fv);

  fv || (fv = 0);

  var x, y, pv_value;

  if (nper == 0) {
    alert('Why do you want to test me with zeros?');
    return 0;
  }
  if (rate == 0) {
    // Interest rate is 0
    pv_value = -(fv + pmt * nper);
  } else {
    x = Math.pow(1 + rate, -nper);
    y = Math.pow(1 + rate, nper);
    pv_value = -(x * (fv * rate - pmt + y * pmt)) / rate;
  }
  pv_value = conv_number(pv_value, 2);
  return pv_value;
}

const MonthWeekRatio = parseFloat(13 / 3);

function calcRepaymentAndAffordability(
  _amount,
  months,
  annual_factor_rate,
  type,
) {
  var result = {};
  const weeks = months * MonthWeekRatio;
  const amount = parseFloat(_amount, 10); // DO NOT REMOVE THIS LINE IT IS IMPORTANT
  const weekly_rate = AnnualFactorToWeeklyRateMapper[annual_factor_rate];
  if (type === TAB_LABEL.MONTHLY || type === TAB_LABEL.WEEKLY) {
    // repayments
    const weekly_repayment = -PMT(weekly_rate, weeks, _amount);
    const total_repayment = weekly_repayment * weeks;
    result.total_repayment = parseFloat(total_repayment).toFixed(2);
    result.weekly_repayment = parseFloat(weekly_repayment).toFixed(2);
    result.monthly_repayment = parseFloat(
      weekly_repayment * MonthWeekRatio,
    ).toFixed(2);
    result.total_interest = parseFloat(total_repayment - amount).toFixed(2);
    result.repayment_afforability_amount =
      type === TAB_LABEL.MONTHLY
        ? (total_repayment / months).toFixed(2)
        : (total_repayment / weeks).toFixed(2);
  } else if (type === TAB_LABEL.AFFORADABILITY) {
    // affordability
    const total_loan_amount = -Math.round(PV(weekly_rate, weeks, amount));
    const total_repayment = amount * weeks;
    result.total_repayment = parseFloat(total_repayment).toFixed(2);
    result.repayment_afforability_amount =
      parseFloat(total_loan_amount).toFixed(2);
    result.total_interest = parseFloat(
      total_repayment - total_loan_amount,
    ).toFixed(2);
  }
  insertCalcResult(result);
}

function formatMoney(amount, decimalCount, decimal, thousands) {
  try {
    decimalCount = decimalCount === undefined ? 2 : decimalCount;
    decimal = decimal || '.';
    thousands = thousands || ',';

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.error(e);
  }
}

const $calculatorAffordability = $('#repayment_afforability_amount');
const $calculatorTotalInterest = $('#total_interest');
const $calculatorTotalRepayment = $('#total_repayment');
function insertCalcResult(result) {
  $calculatorAffordability.text(
    formatMoney(result.repayment_afforability_amount),
  );
  $calculatorTotalInterest.text(formatMoney(result.total_interest));
  $calculatorTotalRepayment.text(formatMoney(result.total_repayment));
}

function calculateUserInputAmount(type) {
  var newAmount = '';
  if (type === TAB_LABEL.REPAYMENTS) {
    const totalInterest = parseFloatCurrency($calculatorTotalInterest.text());
    const totalRepayment = parseFloatCurrency($calculatorTotalRepayment.text());
    newAmount = totalRepayment - totalInterest;
  } else if (type === TAB_LABEL.AFFORADABILITY) {
    const previousValue = parseFloatCurrency($calculatorAffordability.text());
    newAmount = previousValue;
    const timeFrame =
      $weeklySelector.val() === 'true' ? TAB_LABEL.WEEKLY : TAB_LABEL.MONTHLY;
    if (timeFrame === TAB_LABEL.MONTHLY) {
      newAmount = parseFloat(newAmount / MonthWeekRatio).toFixed(2);
    }
  }

  newAmount = formatMoney(newAmount, 2);
  newAmount = parseFloat(newAmount) === 0 ? '' : newAmount;
  $calculatorInput.val(newAmount);
}

const $calculatorAffordabilityTab = $('#calc-affordability');
const $calculatorRepaymentsTab = $('#calc-repayments');
const $loanAmountText = $('#loan-amount-text');
function handleClickCalcTab(event) {
  const value = event.target.value;
  if ($calculatorTabs.attr('value') !== value) {
    if (value === TAB_LABEL.AFFORADABILITY) {
      $calculatorAffordabilityTab.addClass('calculator-tab-selected');
      $calculatorRepaymentsTab.removeClass('calculator-tab-selected');
      $calculatorInput.attr(
        'placeholder',
        'Min $' + LOAN_VALIDATION.MIN_WEEKLY_AFFORADABILITY,
      );
      $calculatorTabs.attr('value', 'affordability');
      $loanAmountText.text('Choose desired weekly repayments');
      $calculatorTimeFrameButtons.addClass('hide');
      calculateUserInputAmount(value);
      if ($window.width() <= 768) {
        shouldAnimation = false;
      }
      toggleRepaymentTimeFrame(value);
    }
    if (value === TAB_LABEL.REPAYMENTS) {
      $calculatorRepaymentsTab.addClass('calculator-tab-selected');
      $calculatorAffordabilityTab.removeClass('calculator-tab-selected');
      $calculatorInput.attr(
        'placeholder',
        'Min $' +
          LOAN_VALIDATION.REPAYMENTS_MIN_LOAN_AMOUNT / 1000 +
          'K - Max $' +
          LOAN_VALIDATION.REPAYMENTS_MAX_LOAN_AMOUNT / 1000 +
          'K',
      );
      $calculatorTabs.attr('value', 'repayments');
      $loanAmountText.text('Enter your desired loan amount');
      $calculatorTimeFrameButtons.removeClass('hide');
      calculateUserInputAmount(value);
      const timeFrame =
        $weeklySelector.val() === 'true' ? TAB_LABEL.WEEKLY : TAB_LABEL.MONTHLY;
      if ($window.width() <= 768) {
        shouldAnimation = false;
      }
      toggleRepaymentTimeFrame(timeFrame);
    }
  }
}

const $calculatorPeriodInput = $('#calc-period');
function handleSelectRepaymentPeriod(event) {
  const value = event.target.value;
  const block_selector = '#block-' + value;
  $calculatorPeriodInput.val(value);
  $calculatorPeriodSelect.val(value).niceSelect('update');
  $('.calculator-block-selected').removeClass('calculator-block-selected');
  $(block_selector).addClass('calculator-block-selected');
  triggerCalculation();
}

const $monthlySelector = $('#monthly-selector');
const $repaymentTimeFrameText = $('#repayment-time-frame-text');
const $hoverText = $('#calculator-hover-text');
function toggleRepaymentTimeFrame(timeFrame) {
  if (timeFrame === TAB_LABEL.MONTHLY) {
    $monthlySelector.addClass('active').attr('value', true);
    $weeklySelector.removeClass('active').attr('value', false);
    $repaymentTimeFrameText.text('Your Monthly Payment');
    $hoverText.text(
      'Repayment amount is estimated based on your chosen loan amount, % Simple Interest Per Annum and term length, including an origination fee of 2.5 % of the total loan amount.',
    );
  } else if (timeFrame === TAB_LABEL.WEEKLY) {
    $weeklySelector.addClass('active').attr('value', true);
    $monthlySelector.removeClass('active').attr('value', false);
    $repaymentTimeFrameText.text('Your Weekly Payment');
    $hoverText.text(
      'Repayment amount is estimated based on your chosen loan amount, % Simple Interest Per Annum and term length, including an origination fee of 2.5 % of the total loan amount.',
    );
  } else if (timeFrame === TAB_LABEL.AFFORADABILITY) {
    $repaymentTimeFrameText.text('Total Loan Amount');
    $hoverText.text(
      'Loan amount is estimated based on your chosen weekly repayment amount, % Simple Interest Per Annum and term length. The total repayment amount also includes an origination fee of 2.5% of the total loan amount.',
    );
  }
  triggerCalculation(0);
}

const $twelveMonthsSelector = $('#block-12');
const $currentSelectedDot = $('.current-selected-dot');
function clickReset() {
  $calculatorInput.val('');
  $calculatorFormErrorMessage.text('');
  $calculatorInput.removeClass('calculator-loan-amount__error');
  $calculatorPeriodInput.val(DEFAULT_TERM);
  $calculatorPeriodSelect.val(DEFAULT_TERM).niceSelect('update');
  $('.calculator-block-selected').removeClass('calculator-block-selected');
  $twelveMonthsSelector.addClass('calculator-block-selected');

  $calculatorProgressBar.css(
    'background-image',
    'linear-gradient(90deg, #FD55D8 40%, #eceff4 0%)',
  );
  $calculatorProgressInside.css(
    'background-image',
    'linear-gradient(90deg, #FD55D8 40%, #eceff4 0%)',
  );
  $dotNumberWrapper.css('left', 'calc(40% - 22px)');
  $calculatorInterestNumber.text(DEFAULT_INTEREST_RATE);
  $draggableDot.slider('value', DEFAULT_INTEREST_RATE);
  $calculatorInterestRate.children('div').each(function (index, element) {
    var $currentElement = $(this);
    if (index < 3) {
      $currentElement.addClass('selected-dot');
    } else {
      $currentElement.removeClass('selected-dot');
    }
  });
  $currentSelectedDot.css('left', '-60%');
  $('.calculator-results-slide-in').removeClass('calculator-results-slide-in');
  insertCalcResult({
    repayment_afforability_amount: '00.00',
    total_interest: '0.00',
    total_repayment: '00.00',
  });
  isCalculated = false;
}

function handleClickReturn() {
  $calculatorSlider.slick('slickPrev');
  $calculatorWidgetSlider.slick('slickPrev');
  setTimeout(function () {
    $calculatorSlider.slick('slickFilter', ':even');
    $calculatorWidgetSlider.slick('slickFilter', ':even');
  }, 200);
  insertCalcResult({
    repayment_afforability_amount: '00.00',
    total_interest: '0.00',
    total_repayment: '00.00',
  });
  isCalculated = false;
}

function validateLoanAmountInput(event) {
  const char = event.key;
  const input = event.target;
  if (event.key === 'Tab') return;
  event.preventDefault();

  if (!_allowedCharacters.includes(char)) return;

  var new_input = '',
    currentValue = parseFloatCurrency(input.value);

  if (char === 'Backspace') {
    if (currentValue.includes('.')) {
      currentValue = currentValue.replace(/\..*/, '');
    } else {
      currentValue = currentValue.slice(0, -1);
    }
    new_input = formatMoney(currentValue, 0);
    new_input = new_input === '0' ? '' : new_input;
  } else {
    new_input = formatMoney(currentValue + char, 0);

    const type = $calculatorTabs.attr('value');
    if (
      type === TAB_LABEL.AFFORADABILITY &&
      parseFloat(currentValue + char) > 99999
    )
      return;

    if (!input.value) {
      // input.value === 0
      input.value = char;
      return;
    }
  }
  input.value = new_input;
  $calculatorInput.removeClass('calculator-loan-amount__error');
  // add debounce for auto calculation
  triggerCalculation(1000);
}

function handleClickApply() {
  localStorage.removeItem('initial_application_data'); // ?
  window.location = '/apply';
}

const TAB_LABEL = {
  REPAYMENTS: 'repayments',
  AFFORADABILITY: 'affordability',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};
const LOAN_VALIDATION = {
  REPAYMENTS_MAX_LOAN_AMOUNT: 500000,
  REPAYMENTS_MIN_LOAN_AMOUNT: 10000,
  MIN_WEEKLY_AFFORADABILITY: 90,
};

const _allowedCharacters = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Backspace',
];
function parseFloatCurrency(value) {
  return value.replace(/\$|,+/g, '');
}

$(document).ready(function () {

  $('#calc-period-select').niceSelect();

  $('#weekly-repayment-info-hover').hover(
    function (e) {
      const interestRate = $('#number-display').text();
      const months = isMobileOS()
        ? $('#calc-period-select').val()
        : $('#calc-period').attr('value');
      $('#info-interest-rate').text(interestRate);
      $('#info-months').text(months);

      $('#weekly-repayment-hover-content').addClass('info-hover-show');
    },
    function () {
      $('#weekly-repayment-hover-content').removeClass('info-hover-show');
    },
  );

  init_calculator();
});

// ==========================================
//  Loan Calculator
var rate = 0.0075;

function formatPrice(number) {
  return ('$' + number).replace(/(\d)(?=(\d{3})+$)/g, '$1,');
}
function updateRepayment() {
  var amount = $('#amount-select').val();
  var term = $('#term-select').val();

  var repayment = repaymentAmount(amount, term);
  $('#repayment').text(formatPrice(repayment));
}
function repaymentAmount(_amount, months) {
  var weeks = months * 4.3;
  const amount = parseInt(_amount, 10); // DO NOT REMOVE THIS LINE IT IS IMPORTANT
  return (
    ((amount * rate * Math.pow(1 + rate, weeks)) /
      (1 - Math.pow(1 + rate, weeks))) *
    -1
  ).toFixed();
}

$('#term-select').change(updateRepayment);
$('#amount-select').change(updateRepayment);